import React, { Component } from "react";

import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";

import { Card, Title, Left, Right, Middle, Img } from "../Elements/Card";
import Contribute from "../Contribute";

function Project(props) {
  return (
    <Link to={props.url}>
      <Card className="project">
        <Img src={props.img} />
        <Title>{props.title}</Title>
        <Left> {props.language}</Left>
        <Right> {props.difficulty}</Right>
      </Card>
    </Link>
  );
}

// TODO: Create component for mapping projects and for graphql query

const Projects = () => {

  const data = useStaticQuery(webProjectsPageQuery).allTutorial.edges;
  data.sort((a,b) => (new Date(a.node.date) > new Date(b.node.date)) ? -1 : 1);

  return (
  <div id="projects">
    <Container fluid>
      <Row>
        {data.map((item, index) => (
          <Col lg={3} md={6} xs={12} key={index}>
          <Project
            url={`/projects/${item.node.id}`}
            img={item.node.img}
            title={item.node.title}
            difficulty={item.node.difficulty}
            language={item.node.language}
          />
        </Col>
        ))}
        <Col lg={3} md={6} xs={12}>
          <Link to="/contribute">
            <Card>
              <Contribute />
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
  );
}

export default Projects;

export const webProjectsPageQuery = graphql`
  query WebProjectsPageQuery {
    allTutorial(filter: {published: {eq: true}, category: {eq: "Web Development"}}) {
      edges {
        node {
          difficulty
          language
          title
          timeToRead
          id
          img
          date
        }
      }
    }
  }
`;
