import React, { Component } from "react";
import Helmet from "react-helmet";

import Label from "components/Elements/Label";
import WebProjects from "components/Tracks/Web";

import TitleContainer from "components/Elements/TitleContainer";

class Web extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="Learn Web Development | Enlight"
          meta={[
            {
              name: "description",
              content:
                "Learn to code the web by building fun mini-projects and become a member of a community of 50,000 other aspiring developers. Learn modern technologies like HTML, CSS, JavaScript, and React.js to create cutting-edge and repsonsive applications.",
            },
          ]}
        />
        <TitleContainer>
          <br />
          <h1>Learn Web Development</h1>
          <br />
          <Label>HTML, CSS, JavaScript, Node.js</Label>
          <br />
        </TitleContainer>
        <br />
        <WebProjects />
      </div>
    );
  }
}

export default Web;
